<template>
  <div>
    <div class="content" style="padding: 40px">
      <el-form
        style="width: 400px"
        label-position="top"
        label-width="80px"
        :model="form"
        ref="form"
        hide-required-asterisk
        :rules="rules"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName"></el-input>
        </el-form-item>
        <el-form-item label="所属组织" prop="belongDeptId">
          <select-tree
            v-if="loading.tree"
            ref="selectTree"
            :props="props"
            placeholder="请选择组织"
            :options="organization"
            :value="form.belongDeptId"
            :clearable="true"
            :accordion="true"
            @getValue="getValue($event)"
          />
        </el-form-item>
        <el-form-item label="角色描述" prop="roleDesc">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4 }"
            maxlength="200"
            resize="none"
            placeholder="最多输入200个字符,支持英文、字母、下划线汉字"
            v-model="form.roleDesc"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="平台锁机权限" prop="ofLockDevice">
          <el-switch
            v-model="form.ofLockDevice"
            active-value="1"
            inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            style="margin-left:10px;"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="web权限">
          <el-tree
            default-expand-all
            :data="webfns"
            show-checkbox
            node-key="fnId"
            :props="defaultProps"
            ref="webTree"
            check-strictly
            @check-change="checkWebChange"
          >
          </el-tree>
        </el-form-item>
        <el-form-item label="app权限">
          <el-tree
            default-expand-all
            :data="appfns"
            show-checkbox
            node-key="fnId"
            :props="defaultProps"
            ref="appTree"
            check-strictly
            @check-change="checkAPPChange"
          >
          </el-tree>
        </el-form-item>
      </el-form>
    </div>
    <el-button class="button_cancel button" @click="cancel">取消</el-button>
    <el-button class="button_preserve" @click="handleSave">保存</el-button>
  </div>
</template>

<script>
import { roleRrUserRole } from "@/api/systemAdmin/userAdmin";
import { queryDeptAll } from "../../api/AssetManagement";
export default {
  name: "addRoleControl",
  data() {
    return {
      appfn: [],
      organization: [],
      defaultProps: {
        children: "children",
        label: "fnName"
      },
      form: {
        roleName: "",
        roleDesc: "",
        ofLockDevice: 0,
        belongDeptId: ""
      },
      loading: {
        tree: false
      },
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      },
      rules: {
        roleName: {
          required: true,
          message: "请输入角色名称",
          trigger: "change"
        },
        roleDesc: {
          required: true,
          message: "请输入描述",
          trigger: "change"
        },
        belongDeptId: {
          required: true,
          message: "请选择组织",
          trigger: "change"
        }
      },
      webfn: [],
      webfns: [],
      appfns: []
    };
  },
  created() {
    this.defaultVal = { ...this.form, ...this.$route.query };
    this.form = { ...this.defaultVal };
    this.getorganization();
    const fnIds = this.defaultVal.fnIds || [];
    const webfnIds = [];
    const appfnIds = [];
    const arr = JSON.parse(localStorage.getItem("fns"));
    arr.forEach(value => {
      value.children = [];
      if (value.fnId == 27) {
        console.log(value);
      }
      arr.forEach(val => {
        if (value.fnId === val.parentId) {
          value.children.push(val);
        }
      });
      if (value.fnPlatform === 1) {
        if (fnIds.indexOf(value.fnId + "") != -1) {
          webfnIds.push(value.fnId + "");
        }
        if (value.parentId === 0) {
          this.webfn.push(value);
        }
      } else {
        if (fnIds.indexOf(value.fnId + "") != -1) {
          appfnIds.push(value.fnId + "");
        }
        if (value.parentId === 0) {
          this.appfns.push(value);
        }
      }
    });
    this.webfns.push(this.webfn[0]);
    this.webfns.push(this.webfn[1]);
    this.webfns.push(this.webfn[2]);
    this.webfns.push(this.webfn[8]);
    this.webfns.push(this.webfn[6]);
    this.webfns.push(this.webfn[3]);
    this.webfns.push(this.webfn[4]);
    this.webfns.push(this.webfn[7]);
    this.webfns.push(this.webfn[5]);
    this.defaultVal = {
      ...this.defaultVal,
      webfnIds: webfnIds,
      appfnIds: appfnIds
    };
  },
  mounted() {
    console.log(this.defaultVal);
    this.$refs.appTree.setCheckedKeys(this.defaultVal.appfnIds || []);
    this.$refs.webTree.setCheckedKeys(this.defaultVal.webfnIds || []);
  },
  methods: {
    cancel() {
      this.reset();
      this.$router.push({ path: "/dashboard/systemSet/roleControl" });
    },
    getorganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          // if (value.parentId === 0) {
          //   this.organization.push(value);
          // }
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.organization.push(value);
          }
        });
        this.loading.tree = true;
      });
    },
    getValue(value) {
      this.form.belongDeptId = value;
    },
    reset() {
      this.form = { ...this.defaultVal };
      this.$refs.appTree.setCheckedKeys([]);
      this.$refs.webTree.setCheckedKeys([]);
    },
    checkWebChange(data, target) {
      if (target) {
        this.getTreeParent(
          data,
          [],
          JSON.parse(localStorage.getItem("fns")).filter(item => {
            return item.fnPlatform == 1;
          })
        ).forEach(value => {
          this.$refs.webTree.setChecked(value, true);
        });
      } else {
        if (data.children.length)
          this.getTreeChildren(data.children, []).forEach(value => {
            this.$refs.webTree.setChecked(value, false);
          });
      }
    },
    checkAPPChange(data, target) {
      if (target) {
        this.getTreeParent(
          data,
          [],
          JSON.parse(localStorage.getItem("fns")).filter(item => {
            return item.fnPlatform != 1;
          })
        ).forEach(value => {
          this.$refs.appTree.setChecked(value, true);
        });
      } else {
        if (data.children.length)
          this.getTreeChildren(data.children, []).forEach(value => {
            this.$refs.appTree.setChecked(value, false);
          });
      }
    },
    // 找出所有的子节点key
    getTreeChildren(data, arr) {
      data.forEach(value => {
        arr.push(value.fnId);
        if (value.children.length) {
          this.getTreeChildren(value.children, arr);
        }
      });
      return arr;
    },
    // 找出所有父节点的key
    getTreeParent(data, arr, fns) {
      if (data.parentId) {
        arr.push(data.parentId);
        const item = fns.find(el => el.fnId === data.parentId);
        this.getTreeParent(item, arr, fns);
      }
      return arr;
    },
    handleSave() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const config = {
            data: {
              ...this.form,
              fnIds: [
                ...this.$refs.appTree.getCheckedKeys(),
                ...this.$refs.appTree.getHalfCheckedKeys(),
                ...this.$refs.webTree.getCheckedKeys(),
                ...this.$refs.webTree.getHalfCheckedKeys()
              ]
            }
          };
          roleRrUserRole(config).then(({ data }) => {
            console.log(data);
            if (data.resultCode === 0) {
              this.$message({
                showClose: true,
                message: `${this.form.type}成功`,
                type: "success"
              });
              this.$router.push({ path: "/dashboard/systemSet/roleControl" });
            } else {
              this.$message({
                showClose: true,
                message: data.errorDesc || `${this.form.type}失败`,
                type: "error"
              });
              //
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.button {
  margin-top: 20px;
}
</style>
